import * as requestFromServer from "./userCrud";
import { userSlice, callTypes } from "./userSlice";

const { actions } = userSlice;

export const initUser = (queryParams) => (dispatch) => {
  return dispatch(actions.init());
};

export const fetchUsers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getUserList(queryParams)
    .then((response) => {
      const userList = response.data;
      dispatch(actions.usersFetched(userList));
    })
    .catch((error) => {
      error.clientMessage = error.response.data.message || "유저정보가 없습니다.";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchUserByToken = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.userFetched({ user: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getUserByToken(id)
    .then((response) => {
      const user = response.data;
      dispatch(actions.userFetched({ user: user }));
    })
    .catch((error) => {
      error.clientMessage = "유저정보가 없습니다.";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUserById = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.userFetched({ user: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getUserById(id)
    .then((response) => {
      const user = response.data;
      dispatch(actions.userFetched({ user: user }));
    })
    .catch((error) => {
      error.clientMessage = "유저정보가 없습니다.";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
