/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 header-user"
          }
        >
          <img
            src={toAbsoluteUrl("/media/icons/user-icon.png")}
            className="submenu-user-icon"
            alt="유저아이콘"
          />
          <span className="user-text font-weight-bold d-none d-md-inline mr-1">반갑습니다,</span>{" "}
          <span className="user-text font-weight-bolder d-none d-md-inline mr-3">
            {user.name} 님!
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xs">
        <div className="navi-footer  px-8 py-5">
          <Link to="/logout" className="btn color-primary font-size-14">
            <span class="font-color-white font-w-500">로그아웃</span>
          </Link>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
