import React from "react";

export function ResultLoading() {
  return (
    <div className="loading-wrap result">
      <div className="loading-cont">
        <div className="load-result">
          <div className="row rowc">
            <div className="circle c1"></div>
            <div className="circle c2"></div>
          </div>
          <div className="row2 rowc">
            <div className="circle c1"></div>
            <div className="circle c2"></div>
          </div>
          <div className="row3 rowc">
            <div className="circle c1"></div>
            <div className="circle c2"></div>
          </div>
          <div className="row4 rowc">
            <div className="circle c1"></div>
            <div className="circle c2"></div>
          </div>
          <div className="row5 rowc">
            <div className="circle c1"></div>
            <div className="circle c2"></div>
          </div>
          <div className="row6 rowc">
            <div className="circle c1"></div>
            <div className="circle c2"></div>
          </div>
          <div className="row7 rowc">
            <div className="circle c1"></div>
            <div className="circle c2"></div>
          </div>
          <div className="row8 rowc">
            <div className="circle c1"></div>
            <div className="circle c2"></div>
          </div>
          <div className="row9 rowc">
            <div className="circle c1"></div>
            <div className="circle c2"></div>
          </div>
          <div className="row10 rowc">
            <div className="circle c1"></div>
            <div className="circle c2"></div>
          </div>
          <div className="row11 rowc">
            <div className="circle c1"></div>
            <div className="circle c2"></div>
          </div>
          <div className="row12 rowc">
            <div className="circle c1"></div>
            <div className="circle c2"></div>
          </div>
          <div className="row13 rowc">
            <div className="circle c1"></div>
            <div className="circle c2"></div>
          </div>
        </div>
        <div className="load-txt font-size-16">AI 결과 분석 중...</div>
      </div>
    </div>
  );
}
