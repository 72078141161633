import React from 'react';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../_metronic/_helpers';

export function Message(message) {
    return (
        <div className="message-wrap">
            <div className="message">
                <span className="svg-icon svg-icon-3x">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")}></SVG>
                </span>
                <span className="message-txt font-size-16 font-w-500">
                    {message.message}
                </span>
            </div>
        </div>
    )
}