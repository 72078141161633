/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation, Link } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";

export function BreadCrumbs() {
  const location = useLocation();

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "breadcrumb-item-active" : "";
  };

  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-500 p-0 my-2">
      <li className="breadcrumb-item-main">
        <Link to="/main">
          <i className="flaticon2-shelter text-muted font-size-14" />
        </Link>
      </li>
      <li className="breadcrumb-item" data-menu-toggle="hover" aria-haspopup="true">
        <Link className={`text-muted ${getMenuItemActive("/predict")}`} to="/predict-input">
          임상정보기반
        </Link>
      </li>
      <li className="breadcrumb-item" data-menu-toggle="hover" aria-haspopup="true">
        <Link className={`text-muted ${getMenuItemActive("/foodpattern")}`} to="/foodpattern-input">
          식이패턴분석
        </Link>
      </li>
      <li className="breadcrumb-item" data-menu-toggle="hover" aria-haspopup="true">
        <Link className={`text-muted ${getMenuItemActive("/ogtt")}`} to="/ogtt-input">
          혈당반응 유형 분석
        </Link>
      </li>
      <li className="breadcrumb-item" data-menu-toggle="hover" aria-haspopup="true">
        <Link className={`text-muted ${getMenuItemActive("/foodblood")}`} to="/foodblood-list">
          연속혈당기반
        </Link>
      </li>
    </ul>
  );
}
