/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { List } from "devextreme-react/list";
import * as actions from "../../../../../app/modules/User/_redux/userActions";

export function AsideMenuList({ layoutProps }) {
  const dispatch = useDispatch();

  const userList = useSelector(({ user }) => user.userList, shallowEqual);

  const handleListSelectionChange = (e) => {
    dispatch(actions.fetchUserById(e.addedItems[0].id));
  };

  const renderListItem = (item) => {
    return (
      <div className="user-set">
        <div className="user-num">{item.id}</div>
        <div className="user-info">
          <div className="user-name">
            {item.name}&nbsp;
            <span className="user-id">({item.user_id})</span>
          </div>
          <div className="user-phone">{item.tel}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <div className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <div className={`menu-item menu-item-submenu`}>
          <div className="menu-link">
            <span className="menu-text">검색</span>
            <Link to="/userList">
              <button className="btn color-light font-size-12 px-4 py-2">
                <span className="font-color-white letter-space-1 font-w-500">유저 관리</span>
              </button>
            </Link>
          </div>
          {/* <div className="menu-content">
            <TextBox placeholder="이름/아이디/전화번호 검색..." />
          </div> */}
          <div className="menu-content userlist">
            <List
              searchEnabled={true}
              dataSource={userList}
              searchExpr={["user_id", "name", "tel"]}
              selectionMode="single"
              onSelectionChanged={handleListSelectionChange}
              useNativeScrolling={true}
              bounceEnabled={true}
              itemRender={renderListItem}
              elementAttr={{ class: "search_list" }}
            />
          </div>
        </div>
        {/*end::1 Level*/}
      </div>
      {/* end::Menu Nav */}
    </>
  );
}
