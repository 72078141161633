import React, { useMemo } from "react";
import { Link, NavLink } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../_partials/dropdowns";
import { shallowEqual, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";

export function HeaderMobile() {
  const uiService = useHtmlClassService();
  const auth = useSelector(({ auth }) => auth.user, shallowEqual);
  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay: objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile"),
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header Mobile*/}
      <div
        id="kt_header_mobile"
        className={`header-mobile align-items-center print-hide ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        {/*begin::Logo*/}
        <NavLink className="menu-link" to="/main">
          <span className="menu-text">당뇨병 발생 예측</span>
        </NavLink>
        {/*end::Logo*/}

        {/*begin::Toolbar*/}
        <div className="d-flex align-items-center">
          {layoutProps.asideDisplay && auth.level === 900 && (
            <>
              {/*begin::Aside Mobile Toggle*/}
              <button className="btn p-0" id="kt_aside_mobile_toggle">
                <span className="svg-icon svg-icon-xl">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
                </span>
              </button>
              {/*end::Aside Mobile Toggle*/}
            </>
          )}

          {/*begin::Topbar Mobile Toggle*/}
          {!layoutProps.offcanvas && (
            <>
              <Dropdown drop="down" alignRight>
                <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
                  <button className="btn btn-hover-text-primary p-0 ml-2">
                    <span className="svg-icon svg-icon-xl">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
                    </span>
                  </button>
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                  {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
                  <div className="navi-footer  px-8 py-5">
                    <Link to="/logout" className="btn btn-light-primary font-weight-bold">
                      로그아웃
                    </Link>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
          {/*end::Topbar Mobile Toggle*/}
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Header Mobile*/}
    </>
  );
}
