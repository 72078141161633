import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
// import * as actions from "../../app/modules/User/_redux/userActions";
import * as actions from "../../../../app/modules/User/_redux/userActions";
class Logout extends Component {
  componentDidMount() {
    this.props.initUser();
    this.props.logout();
  }

  render() {
    const { hasAuthToken } = this.props;
    actions.initUser();
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }), {
  ...auth.actions,
  ...actions,
})(Logout);
