import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import { fromPairs } from 'lodash';

const initialValues = {
  fullname: "",
  email: "",
  username: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

export function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const schema = yup
    .object({
      userId: yup.string().required("아이디를 입력하세요.").nullable(),
      password: yup.string().required("비밀번호를 선택하세요."),
      zip: yup.string().required("우편번호를 입력하세요."),
      address: yup.string().required("주소를 입력하세요."),
      addressDetail: yup.string().required("상세주소를 입력하세요."),
      tel1: yup.string().required("전화번호를 입력하세요.").nullable(),
      tel2: yup.string().required("전화번호를 입력하세요.").nullable(),
      tel3: yup.string().required("전화번호를 입력하세요.").nullable(),
      name: yup.string().required("이름을 입력하세요."),
      height: yup.string().required("키 정보를 입력하세요."),
      weight: yup.string().required("몸무게 정보를 입력하세요.")
    })
    .required();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      register(values.email, values.fullname, values.username, values.password)
        .then(({ data: { authToken } }) => {
          props.register(authToken);
          disableLoading();
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
          disableLoading();
        });
    },
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const openPost = () => {
    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function (data) {
          let addr = ""; // 주소 변수
          let extraAddr = ""; // 참고항목 변수

          if (data.userSelectedType === "R") {
            addr = data.address;
          } else {
            addr = data.jibunAddress;
          }

          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr += extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            addr += extraAddr;
          }

          setValue("zip", data.zonecode);
          setValue("address", addr);
        },
      });
      postcode.open();
    });
  };

  return (
    <div className="login-form register" style={{ display: "block" }}>
      <div className="login-tit register">
        <h3 className="font-size-h1">
          회원가입
        </h3>
      </div>
      <form
        id="kt_login_signin_form"
        className="form register fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Title */}
        <div className="membership-init-tit">
          회원정보 입력
        </div>
        {/* end: Title */}

        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: ID */}
        <div className="form-group fv-plugins-icon-container register grid-3">
          <label className="form-label" for="register_id">
            ID
          </label>
          <input
            id="register_id"
            placeholder="ID입력"
            type="text"
            className="form-control form-control-solid h-auto py-3 px-6"
            name="register-id"
            {...register("userId")}
          />
          <button type="button" className="btn btn-sub form id-confirm">
            ID확인
          </button>
        </div>
        {/* end: ID */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container register grid-3">
          <label className="form-label" for="register_password">
            비밀번호
          </label>
          <input
            id="register_password"
            placeholder="비밀번호 입력"
            type="password"
            className="form-control form-control-solid h-auto py-3 px-6"
            name="password"
            {...register("password")}
          />
          <input
            placeholder="비밀번호 확인"
            type="password"
            className="form-control form-control-solid h-auto py-3 px-6"
            name="changepassword"
          />
        </div>
        {/* end: Password */}

        {/* begin: Phone */}
        <div className="form-group fv-plugins-icon-container register grid-6">
          <label className="form-label" for="register_phone_1">
            연락처
          </label>
          <select
            id="register_phone_1"
            type="number"
            className="form-control form-control-solid h-auto py-3 px-6"
            name="phone-1"
            {...register("tel1")}>
            <option>010</option>
            <option>011</option>
            <option>070</option>
            <option>02</option>
            <option>031</option>
            <option>032</option>
            <option>033</option>
            <option>041</option>
            <option>042</option>
            <option>043</option>
            <option>044</option>
            <option>051</option>
            <option>052</option>
            <option>053</option>
            <option>054</option>
            <option>055</option>
            <option>061</option>
            <option>062</option>
            <option>063</option>
            <option>064</option>
          </select>
          <span>-</span>
          <input
            id="register_phone_2"
            type="number"
            className="form-control form-control-solid h-auto py-3 px-6 tel-num"
            name="phone-2"
            {...register("tel2")}
          />
          <span>-</span>
          <input
            id="register_phone_3"
            type="number"
            className="form-control form-control-solid h-auto py-3 px-6 tel-num"
            name="phone-3"
            {...register("tel3")}
          />
        </div>
        {/* end: Phone */}

        {/* begin: Address */}
        <div className="form-group fv-plugins-icon-container register grid-3">
          <label className="form-label" for="register_zip">
            주소
          </label>
          <input
            id="register_zip"
            placeholder="우편번호입력"
            type="text"
            className="form-control form-control-solid h-auto py-3 px-6"
            name="zip"
            {...register("zip")}
          />
          <button type="button" className="btn btn-sub form zip" onClick={openPost}>
            우편번호 검색
          </button>
        </div>
        <div className="form-group fv-plugins-icon-container register grid-2">
          <label for="register_address">
          </label>
          <input
            id="register_address"
            placeholder="주소 입력"
            type="text"
            className="form-control form-control-solid h-auto py-3 px-6"
            name="address"
            {...register("address")}
          />
        </div>
        <div className="form-group fv-plugins-icon-container register grid-2">
          <label for="register_address_detail">
          </label>
          <input
            id="register_address_detail"
            placeholder="상세주소 입력"
            type="text"
            className="form-control form-control-solid h-auto py-3 px-6"
            name="address-detail"
            {...register("addressDetail")}
          />
        </div>
        {/* end: Address */}

        {/* begin: Title */}
        <div className="membership-init-tit">
          기본정보 입력
        </div>
        {/* end: Title */}

        <div className="form-group fv-plugins-icon-container register grid-4">
          {/* begin: ID */}
          <div className="basic-info-group">
            <label className="form-label" for="register_name">
              이름
            </label>
            <input
              id="register_name"
              placeholder="이름 입력"
              type="text"
              className="form-control form-control-solid h-auto py-3 px-6"
              name="fullname"
              {...register("name")}
            />
          </div>
          {/* end: ID */}
        </div>

        <div className="form-group fv-plugins-icon-container register grid-4">
          {/* begin: Password */}
          <div className="basic-info-group">
            <label className="form-label" for="register_age">
              나이
            </label>
            <input
              id="register_age"
              placeholder="만 나이 입력"
              type="number"
              className="form-control form-control-solid h-auto py-3 px-6"
              name="age"
              {...register("age")}
            />
          </div>
          {/* end: Password */}

          {/* begin: Phone */}
          <div className="basic-info-group">
            <label className="form-label" for="register_gender">
              성별
          </label>
            <select
              id="register_gender"
              type="text"
              className="form-control form-control-solid h-auto py-3 px-6"
              name="gender"
              {...register("gender")}
            >
              <option>남자</option>
              <option>여자</option>
            </select>
          </div>
          {/* end: Phone */}
        </div>

        <div className="form-group fv-plugins-icon-container register grid-4">
          {/* begin: Address */}
          <div className="basic-info-group">
            <label className="form-label" for="register_height">
              키
          </label>
            <input
              id="register_height"
              placeholder="cm"
              type="number"
              className="form-control form-control-solid h-auto py-3 px-6"
              name="height"
              {...register("height")}
            />
          </div>
          {/* end: Address */}

          {/* begin: Address */}
          <div className="basic-info-group">
            <label className="form-label" for="register_weight">
              몸무게
          </label>
            <input
              id="register_weight"
              placeholder="kg"
              type="number"
              className="form-control form-control-solid h-auto py-3 px-6"
              name="weight"
              {...register("weight")}
            />
          </div>
          {/* end: Address */}
        </div>

        <div className="form-group fv-plugins-icon-container grid-4 register">
          {/* begin: Address */}
          <div className="basic-info-group">
            <label className="form-label" for="register_bmi">
              BMI
            </label>
            <input
              id="register_bmi"
              type="number"
              className="form-control form-control-solid h-auto py-3 px-6"
              name="number"
              {...register("bmi")}
              readOnly
            />
          </div>
          {/* end: Address */}

          {/* begin: Address */}
          <div className="basic-info-group bmi">
            <span className="bmi-exp">
              ※ BMI는 ‘본인 몸무게(kg)/(키(m)x키(m))’ 로<br />
              &nbsp;&nbsp;&nbsp;키와 몸무게 입력 시 자동 계산 됩니다.
            </span>
          </div>
          {/* end: Address */}
        </div>

        {/* begin: Terms and Conditions */}
        {/* <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
            />
            <Link
              to="/terms"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              I agree the Terms & Conditions
            </Link>
            <span />
          </label>
        </div> */}
        {/* end: Terms and Conditions */}
        <div className="btn-set register">
          <button
            type="button"
            className="btn btn-cancel"
          >
            <Link to="/auth/login">
              취소
            </Link>
          </button>
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms
            }
            className="btn btn-main register px-9 py-4"
          >
            <span>회원가입</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
