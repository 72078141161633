import axios from "axios";

export const USER_URL = `${process.env.REACT_APP_API_URL}/user`;

export function getUserByToken() {
  return axios.get(`${USER_URL}/user-info`);
}

export function getUserById(id) {
  return axios.get(`${USER_URL}/info-by-id/${id}`);
}

export function getUserList() {
  return axios.get(`${USER_URL}/list`);
}

export function create(data) {
  return axios.post(`${USER_URL}/create`, data);
}

export function update(data) {
  return axios.put(`${USER_URL}/update`, data);
}

export function cancelRequest(data) {
  return axios.post(`${USER_URL}/cancel/request`, data);
}
