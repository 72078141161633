import React from "react";
import { ReactComponent as ImgWithdrawal } from "../../style/media/images/img-withdrawal.svg";
import { ReactComponent as IconApp } from "../../style/media/icons/icon-app.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { cancelRequest } from "../modules/User/_redux/userCrud";

export default function Withdrawal() {
  const schema = yup.object({
    user_id: yup.string().required("아이디를 입력하세요"),
    tel: yup.string().required("전화번호를 입력하세요"),
    name: yup.string().required("이름을 입력하세요"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    cancelRequest(data)
      .then(() => {
        alert("회원 탈퇴 신청이 완료되었습니다.");
      })
      .catch((err) => {
        alert(err.response.data.message);
      })
      .finally(() => {});
    console.log(data);
  };

  return (
    <div className="withdrawal">
      <section className="withdrawal-section">
        <div className="withdrawal-tit">
          <ImgWithdrawal />
          개인 맞춤형 당뇨병 예방
        </div>
        <div className="withdrawal-box">
          <div className="tit-box main">회원 탈퇴 신청</div>
          <div className="content-box">
            <p>
              서비스 탈퇴 요청 후 영업일 기준 1주일 이내로 회원정보는{" "}
              <span className="underline">모두 삭제</span>되며, 탈퇴처리 완료 시 별도의 문자메세지를
              통해 안내해 드립니다.
            </p>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>요청</th>
                <th>삭제되는 정보</th>
                <th>처리내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>회원탈퇴</td>
                <td>아이디, 이름, 생년월일, 주소, 성별, 건강정보</td>
                <td>회원탈퇴 처리 시 즉시 삭제</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="withdrawal-box">
          <div className="tit-box">
            회원정보 입력 <span>*</span>
          </div>
          <div className="content-sub-box">
            <p>탈퇴를 희망하시는 계정의 정보를 입력해 주세요.</p>
          </div>
          <form className="input-form" onSubmit={handleSubmit(onSubmit)}>
            <label className="input-box">
              이름
              <input type="text" name="name" {...register("name")} />
              <p style={{ color: "red" }}>{errors.name?.message}</p>
            </label>
            <label className="input-box">
              아이디
              <input type="text" {...register("user_id")} />
              <p style={{ color: "red" }}>{errors.user_id?.message}</p>
            </label>
            <label className="input-box">
              전화번호
              <input type="text" {...register("tel")} placeholder="숫자만 입력" />
              <p style={{ color: "red" }}>{errors.tel?.message}</p>
            </label>
            <button type="submit">회원탈퇴 신청</button>
          </form>
        </div>
      </section>
      <div className="withdrawal-footer">
        <IconApp width={18} height={18} />
        당뇨프리
      </div>
    </div>
  );
}
