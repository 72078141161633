import React, { useEffect, useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../_core/MetronicLayout";
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { LayoutInit } from "./LayoutInit";
import { SubHeader } from "./subheader/SubHeader";
import { ScrollTop } from "./extras/ScrollTop";
import { useLocation } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../app/modules/User/_redux/userActions";

export function Layout({ children }) {
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth.user, shallowEqual);

  useEffect(() => {
    if (auth.level !== 900) {
      dispatch(actions.fetchUserByToken(auth.id));
    } else {
      dispatch(actions.fetchUsers());
    }
  }, [auth.id, auth.level, dispatch]);
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      // asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      asideDisplay: auth.level === 900,
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        "header.self.fixed.desktop"
      ),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended"),
    };
    // eslint-disable-next-line
  }, [uiService]);

  const pathName = useLocation().pathname;

  return layoutProps.selfLayout !== "blank" ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile isActive={pathName.includes("main") ? true : false} />
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div
          className={`d-flex flex-row flex-column-fluid page ${
            layoutProps.asideDisplay ? "" : "no-admin"
          }`}
        >
          {layoutProps.asideDisplay && <Aside />}
          {/*begin::Wrapper*/}
          <div
            className={`d-flex flex-column flex-row-fluid wrapper ${
              auth.level === 900 ? "" : "no-padding"
            }`}
            id="kt_wrapper"
          >
            <Header
              isActive={
                auth.level !== 900 && pathName.includes("main") ? false : true
              }
            />
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              <SubHeader isActive={pathName.includes("main") ? true : false} />
              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className="content-container">{children}</div>
                  {/*end::Container*/}
                </div>
              )}

              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            {/* <Footer /> */}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <ScrollTop />
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
