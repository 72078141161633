import axios from "axios";
export const CLINICAL_URL = `${process.env.REACT_APP_API_URL}/clinical`;
export const RESULT_URL = `${process.env.REACT_APP_API_URL}/result`;
export const PREDICTION_URL = `${process.env.REACT_APP_API_URL}/prediction`;

export function findPredict(isAdmin, id) {
  if (isAdmin) {
    return axios.get(`${CLINICAL_URL}/admin/find-by-userid/${id}`);
  } else {
    return axios.get(`${CLINICAL_URL}/find-by-token`);
  }
}

export function getPredictResult(id) {
  return axios.get(`${PREDICTION_URL}/all/${id}`);
}

export function createPredict(isAdmin, data) {
  if (isAdmin) {
    return axios.post(`${CLINICAL_URL}/admin/create`, data);
  } else {
    return axios.post(`${CLINICAL_URL}/create`, data);
  }
}

export function updatePredict(isAdmin, data) {
  if (isAdmin) {
    return axios.put(`${CLINICAL_URL}/admin/update`, data);
  } else {
    return axios.put(`${CLINICAL_URL}/update`, data);
  }
}

export function findPredictResult(isAdmin, userId) {
  if (isAdmin) {
    return axios.get(`${CLINICAL_URL}/admin/result/predict/${userId}`);
  } else {
    return axios.get(`${CLINICAL_URL}/result/predict`);
  }
}
export function isSurvey(userId) {
  return axios.get(`${CLINICAL_URL}/survey/${userId}`);
}

export function submitSurvey(data) {
  return axios.post(`${CLINICAL_URL}/survey`, data);
}

export function findSurveyResult(userId) {
  return axios.get(`${RESULT_URL}/all/${userId}`);
}
