import { DataGrid } from "devextreme-react";
import { Column, Export, Scrolling, Selection } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { sendRequest } from "../../../_metronic/_helpers/DataSoureHelpers";

export function CaseReportList() {
  const history = useHistory();

  const [dataGrid, setDataGrid] = useState();

  const dataSource = useMemo(() => {
    const url = `${process.env.REACT_APP_API_URL}/crf`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(url, "GET"),
    });
  }, []);
  const goCreatePage = () => {
    history.push("/crf/create");
  };
  return (
    <>
      <div className="tabs-content-init survey-admin">
        <div className="tabs-content-header">
          <div className="tabs-content-tit" style={{ padding: 20 }}>
            증례기록서(Case Report From, CRF)
          </div>
        </div>
        <div className="search-btn-set" style={{ padding: 10 }}>
          <button type="button" className="btn color-third px-6 py-2 mr-2" onClick={goCreatePage}>
            <span className="font-color-white font-size-14 font-w-500">
              <span className="font-color-white font-size-14 font-w-500">등록</span>
            </span>
          </button>
        </div>

        <div className="record-list survey">
          <DataGrid
            ref={(ref) => setDataGrid(ref)}
            allowColumnResizing={true}
            columnResizingMode="widget"
            dataSource={dataSource}
            showRowLines={true}
            hoverStateEnabled={true}
            showBorders={true}
            columnAutoWidth={true}
            height="65vh"
            keyExpr="id"
          >
            <Export enabled={true} fileName="증례기록서" />
            <Selection showCheckBoxesMode="always" />
            <Scrolling mode="horizontal" showScrollbar="always" useNative={false} />
            <Column
              cellRender={({ row }) => {
                console.log(row);
              }}
            />
            <Column
              cellRender={({ row }) => (
                <button
                  type="button"
                  className="btn color-light px-6 py-2 mr-2"
                  onClick={() => history.push(`/crf/edit/${row.data.id}`)}
                >
                  <span className="font-color-white font-size-14 font-w-500">수정</span>
                </button>
              )}
            />
            <Column dataField="subjectNo" caption="Subject No" />
            <Column dataField="sex" caption="성별" />
            <Column dataField="birth" caption="생년월일" />
            <Column dataField="diagnosis" caption="진단명" />
            <Column dataField="fastingStatus" caption="8시간 이상 공복 여부" />
            <Column dataField="medicalHistory" caption="과거 질병력" />
            <Column dataField="medicationHistory" caption="약물 복용력" />
            <Column dataField="smoke" caption="흡연 여부" />
            <Column dataField="drink" caption="음주 여부" />
            <Column dataField="dietaryInformation" caption="식이 섭취자료" />
            <Column dataField="height" caption="신장" />
            <Column dataField="weight" caption="체중" />
            <Column dataField="bmi" caption="체질량지수" />
            <Column dataField="waistCircumference" caption="허리둘레" />
            <Column dataField="hipCircumference" caption="엉덩이둘레" />
            <Column dataField="systolicBloodPressure" caption="수축기혈압" />
            <Column dataField="diastolicBloodPressure" caption="이완기혈압" />
            <Column dataField="heartRate" caption="맥박" />
            <Column dataField="obesityDiagnosisBodyFatPercentage" caption="비만 진단-체지방률" />
            <Column
              dataField="obesityDiagnosisAbdominalFatPercentage"
              caption="비만 진단-복부지방률"
            />
            <Column dataField="obesityDegree" caption="비만 진단-비만정도" />
            <Column dataField="hba1c" caption="혈색소 A1C" />
            <Column dataField="totalCholesterol" caption="총 콜레스테롤" />
            <Column dataField="hdlCholesterol" caption="HDL 콜레스테롤" />
            <Column dataField="ldlCholesterol" caption="LDL 콜레스테롤" />
            <Column dataField="triglyceride" caption="중성지방" />
            <Column dataField="oglu0" caption="혈당0" />
            <Column dataField="oglu30" caption="혈당30" />
            <Column dataField="oglu60" caption="혈당60" />
            <Column dataField="oglu90" caption="혈당90" />
            <Column dataField="oglu120" caption="혈당120" />
            <Column dataField="oins0" caption="인슐린0" />
            <Column dataField="oins30" caption="인슐린30" />
            <Column dataField="oins60" caption="인슐린60" />
            <Column dataField="oins90" caption="인슐린90" />
            <Column dataField="oins120" caption="인슐린120" />
          </DataGrid>
        </div>
      </div>
    </>
  );
}
