/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const auth = useSelector(({ auth }) => auth.user, shallowEqual);
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-rel ${getMenuItemActive("/main")}`}>
          <NavLink className="menu-link" to="/main">
            <span className="menu-text">당뇨병 발생 예측</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>

        {auth.level === 900 && (
          <li className={`menu-item menu-item-rel ${getMenuItemActive("/survey-list")}`}>
            <NavLink className="menu-link" to="/survey-list">
              <span className="menu-text">만족도 조사</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}

        {auth.level === 900 && (
          <li className={`menu-item menu-item-rel ${getMenuItemActive("/nhis-list")}`}>
            <NavLink className="menu-link" to="/nhis-list">
              <span className="menu-text">건강보험공단 데이터 조회</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}

        {auth.level === 900 && (
          <li className={`menu-item menu-item-rel ${getMenuItemActive("/notice")}`}>
            <NavLink className="menu-link" to="/notice">
              <span className="menu-text">공지사항 관리</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}

        {auth.level === 900 && (
          <li className={`menu-item menu-item-rel ${getMenuItemActive("/walk-state")}`}>
            <NavLink className="menu-link" to="/walk-state">
              <span className="menu-text">걷기미션 참여상태 관리</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}

        {auth.level === 900 && (
          <li className={`menu-item menu-item-rel ${getMenuItemActive("/crf/list")}`}>
            <NavLink className="menu-link" to="/crf/list">
              <span className="menu-text">CRF 관리</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}

        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
