import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { createCrf, getCrfById, updateCrf } from "./crfCrud";
import { Link, useHistory, useParams } from "react-router-dom";
export default function CaseReportForm() {
  const history = useHistory();
  const { id } = useParams();

  const schema = yup.object({}).required();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (errors && Object.keys(errors).length !== 0) {
      alert(errors[Object.keys(errors)[0]]?.message);
    }
  }, [errors]);

  useEffect(() => {
    if (id) {
      getCrfById(id).then((res) => {
        if (res.data) {
          reset(res.data);
        } else {
          alert("등록된 데이터가 없습니다.");
        }
      });
    }
  }, [id, reset]);

  const goListPage = async () => {
    history.replace("/crf/list");
  };

  const onSubmit = async (data) => {
    try {
      if (id) {
        await updateCrf(data);
      } else {
        await createCrf(data);
      }
      history.goBack();
      // history.push({
      //   pathname: "/crf/list",
      // });
      alert("저장 되었습니다.");
    } catch (err) {
      alert(err.response.data.message, "오류");
    }

    console.log(data);
  };

  return (
    <div className="page-wrap predict">
      <div className="page-tit p1">
        <span className="page-tit-txt">
          증례기록서
          <br /> (Case Report From, CRF)
        </span>
      </div>
      <>
        <button
          type="button"
          className="btn color-third px-6 py-2 mr-2"
          style={{ marginBottom: 10 }}
          onClick={goListPage}
        >
          <span className="font-color-white font-size-14 font-w-500">
            <span className="font-color-white font-size-14 font-w-500">목록보기</span>
          </span>
        </button>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-content-wrap ">
            <div className="table-container">
              {/* 신체계측 */}
              <div className="section-tit p2 top font-w-500">신체계측 입력</div>

              <table className="filter-table tri">
                <tbody>
                  <tr>
                    <td>
                      <label>Subject No.</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("subjectNo")}
                          className="form-control"
                          name="subjectNo"
                        />
                      </div>
                    </td>
                    <td className="fix-width">
                      <label htmlFor="sex">성별</label>
                      <div className="radio-select">
                        <span className="selection">
                          <input
                            type="radio"
                            value="M"
                            className="form-control"
                            {...register("sex")}
                            name="sex"
                            id="sex_1"
                          />
                          <label htmlFor="sex_1">남자</label>
                        </span>
                        <span className="selection">
                          <input
                            type="radio"
                            value="F"
                            className="form-control"
                            {...register("sex")}
                            name="sex"
                            id="sex_2"
                          />
                          <label htmlFor="sex_2">여자</label>
                        </span>
                      </div>
                    </td>
                    <td>
                      <label>생년월일, 나이</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("birth")}
                          className="form-control"
                          name="birth"
                          value={watch("birth")}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>진단명</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("diagnosis")}
                          className="form-control"
                          name="diagnosis"
                        />
                      </div>
                    </td>
                    <td>
                      <label>8시간 이상 공복 여부 및 공복시간</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("fastingStatus")}
                          className="form-control"
                          name="fastingStatus"
                        />
                      </div>
                    </td>
                    <td>
                      <label>과거 질병력</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("medicalHistory")}
                          className="form-control"
                          name="medicalHistory"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>약물복용력</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("medicationHistory")}
                          className="form-control"
                          name="medicationHistory"
                        />
                      </div>
                    </td>
                    <td>
                      <label>흡연</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("smoke")}
                          className="form-control"
                          name="smoke"
                        />
                      </div>
                    </td>
                    <td>
                      <label>음주</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("drink")}
                          className="form-control"
                          name="drink"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>식이섭취자료</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("dietaryInformation")}
                          className="form-control"
                          name="dietaryInformation"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* 신체계측 */}
              <div className="section-tit p2 font-w-500">신체계측 입력</div>
              <table className="filter-table quat">
                <tbody>
                  <tr>
                    <td>
                      <label>신장</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("height")}
                          className="form-control"
                          name="height"
                        />
                      </div>
                    </td>
                    <td>
                      <label>체중</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("weight")}
                          className="form-control"
                          name="weight"
                        />
                      </div>
                    </td>
                    <td>
                      <label>체질량지수</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("bmi")}
                          className="form-control"
                          name="bmi"
                        />
                      </div>
                    </td>
                    <td>
                      <label>허리둘레</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("waistCircumference")}
                          className="form-control"
                          name="waistCircumference"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>엉덩이둘레</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("hipCircumference")}
                          className="form-control"
                          name="hipCircumference"
                        />
                      </div>
                    </td>
                    <td>
                      <label>수축기혈압</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("systolicBloodPressure")}
                          className="form-control"
                          name="systolicBloodPressure"
                        />
                      </div>
                    </td>
                    <td>
                      <label>이완기혈압</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("diastolicBloodPressure")}
                          className="form-control"
                          name="diastolicBloodPressure"
                        />
                      </div>
                    </td>
                    <td>
                      <label>맥박</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("heartRate")}
                          className="form-control"
                          name="heartRate"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>비만 진단-체지방률</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("obesityDiagnosisBodyFatPercentage")}
                          className="form-control"
                          name="obesityDiagnosisBodyFatPercentage"
                        />
                      </div>
                    </td>
                    <td>
                      <label>비만 진단-복부지방률</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("obesityDiagnosisAbdominalFatPercentage")}
                          className="form-control"
                          name="obesityDiagnosisAbdominalFatPercentage"
                        />
                      </div>
                    </td>
                    <td>
                      <label>비만 진단-비만정도</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("obesityDegree")}
                          className="form-control"
                          name="obesityDegree"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* 임상검사 */}
              <div className="section-tit p2 font-w-500">임상검사 입력</div>
              <table className="filter-table tri">
                <tbody>
                  <tr>
                    <td>
                      <label>HbA1C</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("hba1c")}
                          className="form-control"
                          name="hba1c"
                        />
                      </div>
                    </td>
                    <td>
                      <label>Total Cholesterol</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("totalCholesterol")}
                          className="form-control"
                          name="totalCholesterol"
                        />
                      </div>
                    </td>
                    <td>
                      <label>HDL-Cholesterol</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("hdlCholesterol")}
                          className="form-control"
                          name="hdlCholesterol"
                        />
                      </div>
                    </td>
                    <td>
                      <label>LDL-Cholesterol</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("ldlCholesterol")}
                          className="form-control"
                          name="ldlCholesterol"
                        />
                      </div>
                    </td>
                    <td>
                      <label>Triglyceride</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("triglyceride")}
                          className="form-control"
                          name="triglyceride"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>혈당 0분</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("oglu0")}
                          className="form-control"
                          name="oglu0"
                        />
                      </div>
                    </td>
                    <td>
                      <label>혈당 30분</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("oglu30")}
                          className="form-control"
                          name="oglu30"
                        />
                      </div>
                    </td>
                    <td>
                      <label>혈당 60분</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("oglu60")}
                          className="form-control"
                          name="oglu60"
                        />
                      </div>
                    </td>
                    <td>
                      <label>혈당 90분</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("oglu90")}
                          className="form-control"
                          name="oglu90"
                        />
                      </div>
                    </td>
                    <td>
                      <label>혈당 120분</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("oglu120")}
                          className="form-control"
                          name="oglu120"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>인슐린 0분</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("oins0")}
                          className="form-control"
                          name="oins0"
                        />
                      </div>
                    </td>
                    <td>
                      <label>인슐린 30분</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("oins30")}
                          className="form-control"
                          name="oins30"
                        />
                      </div>
                    </td>
                    <td>
                      <label>인슐린 60분</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("oins60")}
                          className="form-control"
                          name="oins60"
                        />
                      </div>
                    </td>
                    <td>
                      <label>인슐린 90분</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("oins90")}
                          className="form-control"
                          name="oins90"
                        />
                      </div>
                    </td>
                    <td>
                      <label>인슐린 120분</label>
                      <div className="number-input">
                        <input
                          type="text"
                          {...register("oins120")}
                          className="form-control"
                          name="oins120"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="btn-set result">
            <button type="submit" className="btn color-dark h-45 py-2 px-8">
              <span className="font-color-white font-size-16 font-w-500">저장</span>
            </button>
          </div>
        </form>
      </>
    </div>
  );
}
