import axios from "axios";

export function sendRequest(url, method, search) {
  method = method || "GET";
  search = search || {};
  const { startDate, endDate } = search;

  if (method === "GET") {
    return axios.get(url).then(({ data }) => {
      if (data.status === 200) {
        if (startDate || endDate) {
          const sDate = new Date(startDate);
          const eDate = new Date(endDate);

          sDate.setHours(sDate.getHours() - 9);
          eDate.setHours(eDate.getHours() + 15);
          eDate.setMilliseconds(eDate.getMilliseconds() - 1);

          const reducedData = data.data.reduce((acc, cur) => {
            const curDate = cur.record_time || cur.date || cur.before_time;
            const startDateCondition = startDate
              ? sDate.getTime() - new Date(curDate).getTime() <= 0
              : true;
            const endDateCondition = endDate
              ? eDate.getTime() - new Date(curDate).getTime() >= 0
              : true;

            if (startDateCondition && endDateCondition) {
              acc.push(cur);
            }
            return acc;
          }, []);

          return reducedData;
        }
        return data.data;
      } else {
        if (search.where === "user-list")
          data = data.reduce((acc, cur) => {
            const isIdCorrect = search.user_id
              ? cur.user_id === search.user_id
                ? true
                : false
              : true;
            const isNameCorrect = search.name ? (cur.name === search.name ? true : false) : true;
            const isTelCorrect = search.tel ? (cur.tel === search.tel ? true : false) : true;
            const isBirthCorrect = search.birth
              ? cur.birth === search.birth
                ? true
                : false
              : true;
            const isSexCorrect =
              search.sex !== "0" ? (cur.sex === search.sex ? true : false) : true;
            const isAgeCorrect = search.age ? (cur.age === search.age ? true : false) : true;

            if (
              isIdCorrect &&
              isNameCorrect &&
              isTelCorrect &&
              isBirthCorrect &&
              isSexCorrect &&
              isAgeCorrect
            ) {
              acc.push(cur);
            }
            return acc;
          }, []);

        return data;
      }
    });
  }

  if (method === "POST") {
    return axios.post(url, search).then(({ data }) => {
      if (data.status === 200) {
        return data.data;
      } else {
        alert(data.msg);
        return false;
      }
    });
  }

  if (method === "PUT") {
    return axios.put(url, search).then(({ data }) => {
      if (data.status === 200) {
      } else {
        throw "Error";
      }
    });
  }

  if (method === "DELETE") {
    return axios.delete(url).then(({ data }) => {
      if (data.status === 200) {
      } else {
        throw "Error";
      }
    });
  }

  const params = Object.keys(search)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(search[key])}`;
    })
    .join("&");

  return fetch(url, {
    method: method,
    body: params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    credentials: "include",
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => text && JSON.parse(text));
    } else {
      return result.json().then((json) => {
        throw json.Message;
      });
    }
  });
}
