import CircularGuage, {
  Font,
  Label,
  Scale,
  SubvalueIndicator,
  Tick,
  ValueIndicator,
} from "devextreme-react/circular-gauge";
import { MinorTick } from "devextreme-react/linear-gauge";
import "devextreme/dist/css/dx.light.css";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Message } from "../../../components/Message";
import { ResultLoading } from "../../../modules/Loading/ResultLoading";
import * as requestFromServer from "../predictCrud";

export default function NewPredictResultPage() {
  const userObj = useSelector(({ user }) => user.user, shallowEqual);
  const auth = useSelector(({ auth }) => auth.user, shallowEqual);
  const [score, setScore] = useState(0); // 당뇨 점수
  const [kdScore, setKdScore] = useState(0); // 신장 점수
  const [trtScore, setTrtScore] = useState(0); // 심혈관 점수
  const [isLoading, setIsLoading] = useState(true);

  const rangeContainer = {
    palette: "Pastel",
    width: 25,
    ranges: [
      {
        startValue: 0,
        endValue: 25,
        color: "#33B900",
      },
      {
        startValue: 25,
        endValue: 50,
        color: "#FEE501",
      },
      {
        startValue: 50,
        endValue: 75,
        color: "#FF8A01",
      },
      {
        startValue: 75,
        endValue: 100,
        color: "#EC0C00",
      },
    ],
  };

  const linearRangeContainer = {
    width: 16,
    ranges: [
      {
        startValue: 0,
        endValue: 1,
        color: "#33B900",
      },
      {
        startValue: 1,
        endValue: 1.5,
        color: "#FEE501",
      },
      {
        startValue: 1.5,
        endValue: 2,
        color: "#FF8A01",
      },
      {
        startValue: 2,
        endValue: 2.5,
        color: "#EC0C00",
      },
    ],
  };
  useEffect(() => {
    if (userObj) {
      requestFromServer
        .getPredictResult(userObj.user["user_id"])
        .then(({ data }) => {
          if (data) {
            setScore(data?.predict?.diabetes ? data?.predict?.diabetes * 100 : 0);
            setKdScore(data?.predict?.kidneys ? data?.predict?.kidneys * 100 : 0);
            setTrtScore(data?.predict?.cardiovascular ? data?.predict?.cardiovascular * 100 : 0);
          } else {
            setScore(0);
            setKdScore(0);
            setTrtScore(0);
          }
        })
        .catch((error) => {
          alert(error?.response?.data?.data?.message || "server Error");
        })
        .finally(() => setIsLoading(false));
    }
  }, [auth.level, userObj]);

  if (!userObj) {
    return <Message message={"대상자를 선택해 주세요."} />;
  }

  const customizeLabel = ({ valueText }) => {
    // return `${valueText} %`;
    return "";
  };

  return !isLoading ? (
    <div className="page-wrap">
      <div className="page-tit p1">
        <span className="page-tit-txt">
          {userObj ? (
            <>
              <span className="tit-username">
                {userObj.user.name}({userObj.user.user_id})
              </span>
              <span>&nbsp;님의&nbsp;</span>
            </>
          ) : (
            ""
          )}
          임상적 특성 기반 예측결과
        </span>
        <button
          className="btn color-primary py-2 px-4 print-hide"
          type="button"
          onClick={window.print}
        >
          <span className="svg-icon-lg mr-2">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Printer.svg")} />
          </span>
          <span className="font-color-white font-size-14 font-w-500">프린트 </span>
        </button>
      </div>

      {/* 입력정보 모니터링 */}

      <div className="page-content-wrap result-page predict page-break">
        <div className="result flex-column">
          <div className="section-tit p2 font-w-500">
            <span className="svg-icon-lg mr-2 ml-1">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/ZoomPlus.svg")} />
            </span>
            <span>당뇨병 발생확률</span>
          </div>

          <div className="section-init flex-row">
            <div className="result-bar circle">
              {/* 웹 화면 차트 */}
              <CircularGuage
                style={{ display: "block" }}
                className="gauge print-hide"
                subvalues={parseFloat(score)}
                value={parseFloat(score)}
                rangeContainer={rangeContainer}
              >
                <Scale tickInterval={25} orientation="outside" minorTickInterval={5}>
                  <MinorTick length={8} width={2} visible={true} />
                  <Tick length={26} width={4} />
                  <Label customizeText={customizeLabel}>
                    <Font size={16} />
                  </Label>
                </Scale>
                <SubvalueIndicator offset={-27} />
                <ValueIndicator type="triangleNeedle" />
              </CircularGuage>

              {/* 프린트 시 출력 차트 */}
              <CircularGuage
                style={{ display: "none" }}
                className="gauge print"
                width={300}
                subvalues={parseFloat(score)}
                value={parseFloat(score)}
                rangeContainer={rangeContainer}
              >
                <Scale tickInterval={25} orientation="outside" minorTickInterval={5}>
                  <MinorTick length={8} width={2} visible={true} />
                  <Tick length={26} width={4} />
                  <Label customizeText={customizeLabel}>
                    <Font size={16} />
                  </Label>
                </Scale>
                <SubvalueIndicator offset={-27} />
                <ValueIndicator type="triangleNeedle" />
              </CircularGuage>
              <div className="result-score-img">
                <img src={toAbsoluteUrl("/media/images/chartgrade.png")} alt="발생위험도" />
              </div>
            </div>

            <div className="section-explain flex-column">
              {/* <div className="result-exp">
                <p>
                  10년 이내 귀하의 당뇨병 발생 위험도는 <span>{getText(score)}</span> 단계 입니다.{" "}
                </p>
                {hrList && hrList.length !== 0 ? (
                  <>
                    <p>
                      귀하는 <span>{hrList.join(", ")}</span>이 당뇨병을 발생하게 할 위험 인자로
                      확인되었습니다.
                    </p>
                  </>
                ) : (
                  <p>
                    귀하는 당뇨병을 발생하게 할 위험인자가 없습니다. 현재 생활습관을 계속 유지하시기
                    바랍니다.
                  </p>
                )}
              </div> */}

              <div className="inner-foot">
                <span>※ &nbsp;주의사항 </span>
                <p>- &nbsp;40세 미만 또는 80세 이상인 분들은 테스트 결과가 부정확할 수 있습니다.</p>
                <p>
                  - &nbsp;공복혈당 126mg/dL 이상인 분들은 당뇨병이 의심되므로 진료를 받아야 합니다.
                </p>
                <p>
                  - &nbsp;위 결과는 질환을 진단하는 용이 아닙니다. 참고용으로만 이용하시기 바라며,
                  본 프로그램 개발자들은 법적, 재정적, 의료적 책임이 없음을 밝힙니다.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="result flex-column">
          <div className="section-tit p2 font-w-500">
            <span className="svg-icon-lg mr-2 ml-1">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/ZoomPlus.svg")} />
            </span>
            <span>신장질환 발생확률</span>
          </div>

          <div className="section-init flex-row">
            <div className="result-bar circle">
              {/* 웹 화면 차트 */}
              <CircularGuage
                style={{ display: "block" }}
                className="gauge print-hide"
                subvalues={parseFloat(kdScore)}
                value={parseFloat(kdScore)}
                rangeContainer={rangeContainer}
              >
                <Scale tickInterval={25} orientation="outside" minorTickInterval={5}>
                  <MinorTick length={8} width={2} visible={true} />
                  <Tick length={26} width={4} />
                  <Label customizeText={customizeLabel}>
                    <Font size={16} />
                  </Label>
                </Scale>
                <SubvalueIndicator offset={-27} />
                <ValueIndicator type="triangleNeedle" />
              </CircularGuage>

              {/* 프린트 시 출력 차트 */}
              <CircularGuage
                style={{ display: "none" }}
                className="gauge print"
                width={300}
                subvalues={parseFloat(kdScore)}
                value={parseFloat(kdScore)}
                rangeContainer={rangeContainer}
              >
                <Scale tickInterval={25} orientation="outside" minorTickInterval={5}>
                  <MinorTick length={8} width={2} visible={true} />
                  <Tick length={26} width={4} />
                  <Label customizeText={customizeLabel}>
                    <Font size={16} />
                  </Label>
                </Scale>
                <SubvalueIndicator offset={-27} />
                <ValueIndicator type="triangleNeedle" />
              </CircularGuage>
              <div className="result-score-img">
                <img src={toAbsoluteUrl("/media/images/chartgrade.png")} alt="발생위험도" />
              </div>
            </div>

            <div className="section-explain flex-column">
              {/* <div className="result-exp">
                <p>
                  10년 이내 귀하의 당뇨병 발생 위험도는 <span>{getText(score)}</span> 단계 입니다.{" "}
                </p>
                {hrList && hrList.length !== 0 ? (
                  <>
                    <p>
                      귀하는 <span>{hrList.join(", ")}</span>이 당뇨병을 발생하게 할 위험 인자로
                      확인되었습니다.
                    </p>
                  </>
                ) : (
                  <p>
                    귀하는 당뇨병을 발생하게 할 위험인자가 없습니다. 현재 생활습관을 계속 유지하시기
                    바랍니다.
                  </p>
                )}
              </div> */}

              <div className="inner-foot">
                <span>※ &nbsp;주의사항 </span>
                <p>- &nbsp;40세 미만 또는 80세 이상인 분들은 테스트 결과가 부정확할 수 있습니다.</p>
                <p>
                  - &nbsp;위 결과는 질환을 진단하는 용이 아닙니다. 참고용으로만 이용하시기 바라며,
                  본 프로그램 개발자들은 법적, 재정적, 의료적 책임이 없음을 밝힙니다.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="result flex-column">
          <div className="section-tit p2 font-w-500">
            <span className="svg-icon-lg mr-2 ml-1">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/ZoomPlus.svg")} />
            </span>
            <span>심혈관질환 발생확률</span>
          </div>

          <div className="section-init flex-row">
            <div className="result-bar circle">
              {/* 웹 화면 차트 */}
              <CircularGuage
                style={{ display: "block" }}
                className="gauge print-hide"
                subvalues={parseFloat(trtScore)}
                value={parseFloat(trtScore)}
                rangeContainer={rangeContainer}
              >
                <Scale tickInterval={25} orientation="outside" minorTickInterval={5}>
                  <MinorTick length={8} width={2} visible={true} />
                  <Tick length={26} width={4} />
                  <Label customizeText={customizeLabel}>
                    <Font size={16} />
                  </Label>
                </Scale>
                <SubvalueIndicator offset={-27} />
                <ValueIndicator type="triangleNeedle" />
              </CircularGuage>

              {/* 프린트 시 출력 차트 */}
              <CircularGuage
                id="trt"
                style={{ display: "none" }}
                className="gauge print"
                width={300}
                subvalues={parseFloat(trtScore)}
                value={parseFloat(trtScore)}
                rangeContainer={rangeContainer}
              >
                <Scale tickInterval={25} orientation="outside" minorTickInterval={5}>
                  <MinorTick length={8} width={2} visible={true} />
                  <Tick length={26} width={4} />
                  <Label customizeText={customizeLabel}>
                    <Font size={16} />
                  </Label>
                </Scale>
                <SubvalueIndicator offset={-27} />
                <ValueIndicator type="triangleNeedle" />
              </CircularGuage>
              <div className="result-score-img">
                <img src={toAbsoluteUrl("/media/images/chartgrade.png")} alt="발생위험도" />
              </div>
            </div>

            <div className="section-explain flex-column">
              <div className="inner-foot">
                <span>※ &nbsp;주의사항 </span>
                <p>- &nbsp;40세 미만 또는 80세 이상인 분들은 테스트 결과가 부정확할 수 있습니다.</p>

                <p>
                  - &nbsp;위 결과는 질환을 진단하는 용이 아닙니다. 참고용으로만 이용하시기 바라며,
                  본 프로그램 개발자들은 법적, 재정적, 의료적 책임이 없음을 밝힙니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {isSurvey && (
        <div className="survey-btn-wrap print-hide">
          <button className="btn color-second h-45 py-2 px-8">
            <Link to="/predict-survey">
              <span className="font-color-white font-size-16 font-w-500">만족도 조사 참여</span>
            </Link>
          </button>
        </div>
      )} */}
    </div>
  ) : (
    <ResultLoading />
  );
}
