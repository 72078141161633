import axios from "axios";
export const CRF_URL = `${process.env.REACT_APP_API_URL}/crf`;

export function getCrfById(id) {
  return axios.get(`${CRF_URL}/${id}`);
}

export function createCrf(data) {
  return axios.post(`${CRF_URL}`, data);
}

export function updateCrf(data) {
  return axios.put(`${CRF_URL}`, data);
}
